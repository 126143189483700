<template></template>

<script>
import ApiService from '@/services/api.service'

export default {
    methods: {
        join() {
            const gameId = this.$route.params.gameId
            ApiService.joinAsync(gameId).then(
                (response) => {
                    this.$bvModal
                        .msgBoxOk('You have joined the team!', {
                            title: 'Success',
                            centered: true,
                        })
                        .then((success) => {
                            this.$router
                                .push({
                                    name: 'gamePlay',
                                    params: {
                                        teamId: response.data.team_id,
                                    },
                                })
                                .catch((err) => {})
                        })
                },
                (error) => {
                    this.$bvModal
                        .msgBoxOk(error.response.data.message, {
                            title: 'Error',
                            centered: true,
                        })
                        .then((success) => {
                            this.$router
                                .push({ name: 'game' })
                                .catch((err) => {})
                        })
                }
            )
        },
    },
    mounted() {
        this.join()
    },
}
</script>
